export enum TabelaSecadoresQualidadeEnum {
    SECADOR = 'secador',
    UNIDADES = 'unidades',
    GRAO = 'grao',
    ENTRADA_TEMPERATURA = 'entradaTemperatura',
    MASSA_TEMPERATURA = 'massaTemperatura',
    SAIDA_TEMPERATURA = 'saidaTemperatura',
    ENTRADA_MAIOR_TEMPERATURA = 'entradaMaiorTemperatura',
    MASSA_MAIOR_TEMPERATURA = 'massaMaiorTemperatura',
    SAIDA_MAIOR_TEMPERATURA = 'saidaMaiorTemperatura',
    ENTRADA_ALERTA = 'entradaAlerta',
    MASSA_ALERTA = 'massaAlerta',
    SAIDA_ALERTA = 'saidaAlerta',
    CARGA_UMIDADE = 'cargaUmidade',
    DESCARGA_UMIDADE = 'descargaUmidade',
    MAX_UMIDADE_CARGA = 'maximaUmidadeCarga',
    MED_UMIDADE_CARGA = 'mediaUmidadeCarga',
    MIN_UMIDADE_CARGA = 'minimoUmidadeCarga',
    MAX_UMIDADE_DESCARGA = 'maximaUmidadeDescarga',
    MED_UMIDADE_DESCARGA = 'mediaUmidadeDescarga',
    MIN_UMIDADE_DESCARGA = 'minimoUmidadeDescarga',
    MODAL = 'modal',
}

export enum SensoresEnum {
    TEMPERATURA_DIGITAL = 'secador_leitura_historico_consolidado.temperatura_digital',
    TEMPERATURA_TERMOPAR = 'secador_leitura_historico_consolidado.temperatura_ditemperatura_termopargital',
    TEMPERATURA_TERMORESISTENCIA = 'secador_leitura_historico_consolidado.temperatura_termoresistencia',
    UMIDADE_RELATIVA = 'secador_leitura_historico_consolidado.umidade_relativa',
    UMIDADE_GRAO = 'secador_leitura_historico_consolidado.umidade_grao',
    PRESSAO = 'secador_leitura_historico_consolidado.pressao'
}


export const StatusComunicacaoSecadorQualidadeBackEnum = {
    COMUNICANDO: 'COMUNICANDO',
    PARCIAL: 'COMUNICANDO_PARCIAL',
    FALHA: 'FALHA_COMUNICACAO',
    AGUARDANDO_LEITURA: 'AGUARDANDO_LEITURA',
};

export const contextoPainelEnum = {
    contexto_qualidade: 'painel_qualidade'
}
