export interface SeriesGraficoBolhaTemperaturaUmidadeGraoInterface {
    name: string,
    data: number[][],
    color: string
}

export enum TemperaturasGraficoBolhaEnum {
    TEMPERATURA_MAXIMA = 'temperaturaMaxima',
    TEMPERATURA_MEDIA = 'temperaturaMedia'
}
