import { parametrosFiltroPersonalizados } from "app/modules/gestao-unidades/operacional/shared/interfaces";
import { SensoresEnum } from "app/modules/gestao-unidades/operacional/shared/enums";

export const OperadoresFiltrosPersonalizadosTiposDeSensores: parametrosFiltroPersonalizados[] = [
    {
        nome: 'TEMPERATURA_DIGITAL',
        valor: SensoresEnum.TEMPERATURA_DIGITAL,
    },
    {
        nome: 'TEMPERATURA_TERMOPAR',
        valor: SensoresEnum.TEMPERATURA_TERMOPAR,
    },
    {
        nome: 'TEMPERATURA_TERMORESISTENCIA',
        valor: SensoresEnum.TEMPERATURA_TERMORESISTENCIA,
    },
    {
        nome: 'UMIDADE_RELATIVA',
        valor: SensoresEnum.UMIDADE_RELATIVA,
    },
    {
        nome: 'UMIDADE_DO_GRAO',
        valor: SensoresEnum.UMIDADE_GRAO,
    },
    {
        nome: 'PRESSAO',
        valor: SensoresEnum.PRESSAO,
    },
];

export const OperadoresFiltrosPersonalizadosAreas: { [key: string]: parametrosFiltroPersonalizados[]} = {
    [SensoresEnum.TEMPERATURA_DIGITAL]: [
        {
            nome: 'SECADOR_VISAO_SCREEN.MASSA_GRAOS',
            valor: 'massa_de_grao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.ENTRADA_AR',
            valor: 'entrada_de_ar',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.SAIDA_AR',
            valor: 'saida_de_ar',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.RECIRCULACAO',
            valor: 'recirculacao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.CONDICAO_EXTERNA',
            valor: 'condicao_externa',
        }
    ],
    [SensoresEnum.TEMPERATURA_TERMOPAR]: [
        {
            nome: 'SECADOR_VISAO_SCREEN.FORNALHA',
            valor: 'FORNALHA',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.ENTRADA_AR',
            valor: 'entrada_de_ar',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.MASSA_GRAOS',
            valor: 'massa_de_grao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.SAIDA_AR',
            valor: 'saida_de_ar',
        }
    ],
    [SensoresEnum.PRESSAO]: [
        {
            nome: 'SECADOR_VISAO_SCREEN.ENTRADA_AR',
            valor: 'entrada_de_ar',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.MASSA_GRAOS',
            valor: 'massa_de_grao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.SAIDA_AR',
            valor: 'saida_de_ar',
        },
    ],
    [SensoresEnum.UMIDADE_RELATIVA]: [
        {
            nome: 'SECADOR_VISAO_SCREEN.MASSA_GRAOS',
            valor: 'massa_de_grao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.SAIDA_AR',
            valor: 'saida_de_ar',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.RECIRCULACAO',
            valor: 'recirculacao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.CONDICAO_EXTERNA',
            valor: 'condicao_externa',
        }
    ],
    [SensoresEnum.TEMPERATURA_TERMORESISTENCIA]: [
        {
            nome: 'SECADOR_VISAO_SCREEN.CARGA_GRAOS',
            valor: 'carga_de_grao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.DESCARGA_GRAOS',
            valor: 'descarga_de_grao',
        },
    ],
    [SensoresEnum.UMIDADE_GRAO]: [
        {
            nome: 'SECADOR_VISAO_SCREEN.CARGA_GRAOS',
            valor: 'carga_de_grao',
        },
        {
            nome: 'SECADOR_VISAO_SCREEN.DESCARGA_GRAOS',
            valor: 'descarga_de_grao',
        }
    ]
}
